import { formatISO } from 'date-fns'
import { Dispatch, FC, SetStateAction } from 'react'
import { FieldValues } from 'react-hook-form'
import pathConfiguration from 'src/configuration/path'
import { ISelectOption } from '~/core/ui/Select'
import { catchErrorFromGraphQL } from '~/core/utilities/catch-api-error'
import { ICandidateProfile } from '~/lib/features/candidates/types'
import CreateTaskMutation from '~/lib/features/tasks/graphql/create-task-mutation'
import { TaskFormInActiveType } from '~/lib/features/tasks/types'
import { useSubmitCommon } from '~/lib/hooks/use-submit-graphql-common'
import useBoundStore from '~/lib/store'
import TaskForm from './TaskForm'
import { IRouterWithID } from '~/core/@types/global'
import { useTranslation } from 'react-i18next'

const AddTaskForm: FC<{
  autoFocus?: boolean
  callback?: () => void
  formInActive?: TaskFormInActiveType
  setFormInActive: Dispatch<SetStateAction<TaskFormInActiveType | undefined>>
  candidateProfile?: ICandidateProfile
  applicantId?: IRouterWithID
  companyId?: number
}> = ({
  autoFocus,
  callback,
  formInActive,
  setFormInActive,
  candidateProfile,
  applicantId,
  companyId
}) => {
  const { t } = useTranslation()
  const { trigger, isLoading } = useSubmitCommon(CreateTaskMutation)
  const setToast = useBoundStore((state) => state.setToast)
  const user = useBoundStore((state) => state.user)

  const onFinish = async (data: FieldValues) => {
    if (isLoading) return
    const formatData = {
      title: data.title,
      ...(data.dueDate ? { dueDate: formatISO(data.dueDate) } : {}),
      assigneeIds: data.assigneeIds.map((assignee: ISelectOption) =>
        Number(assignee.value)
      ),
      ...(candidateProfile?.id && !applicantId
        ? { profileId: Number(candidateProfile.id) }
        : undefined),
      ...(applicantId ? { applicantId: Number(applicantId) } : undefined)
    }

    trigger(formatData).then((result) => {
      if (result.error) {
        return catchErrorFromGraphQL({
          error: result.error,
          page: pathConfiguration.tasks.list,
          setToast
        })
      }
      const { tasksCreate } = result.data
      if (tasksCreate?.task?.id) {
        setToast({
          open: true,
          type: 'success',
          title: t('notification:task:taskCreated'),
          classNameConfig: {
            viewport: 'mb-[48px]'
          }
        })

        setFormInActive(undefined)
        callback && callback()
      }

      return true
    })
  }

  const onClose = () => {
    setFormInActive(undefined)
  }

  return (
    <TaskForm
      applicantId={applicantId}
      autoFocus={autoFocus}
      formInActive={formInActive}
      candidateProfileId={candidateProfile?.id}
      isEdited={false}
      isExpand={formInActive?.type === 'add'}
      setIsExpand={(value) =>
        setFormInActive(value ? { type: 'add', taskId: undefined } : undefined)
      }
      onFinish={onFinish}
      onClose={onClose}
      defaultValue={{
        assigneeIds: [
          {
            value: String(user.id),
            avatar: user.avatarVariants?.thumb?.url,
            avatarVariants: user.avatarVariants,
            supportingObj: {
              name: user.fullName || user.email || '',
              defaultColour: user.defaultColour
            }
          }
        ]
      }}
      companyId={companyId}
      isShowEveryoneOption={false}
    />
  )
}

export default AddTaskForm
