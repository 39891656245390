import { gql } from 'urql'
import { InterviewFeedbackDetailProps } from '../types'

const QueryInterviewFeedbackDetail = gql<
  { interviewsEvaluationShow: InterviewFeedbackDetailProps },
  {
    id: number
    jobIkitEvalId?: number
  }
>`
  query ($id: Int!, $jobIkitEvalId: Int) {
    interviewsEvaluationShow(id: $id, jobIkitEvalId: $jobIkitEvalId) {
      id
      eventTypeDescription
      fromDatetime
      toDatetime
      profile {
        id
        fullName
        email
        headline
        phoneNumber
        address
        avatarVariants
        coverLetter
        links
        sourced
        sourcedDescription
        sourcedName
        sourcedNameDescription
        links
        createdAt
        profileCvs {
          id
          attachments {
            id
            file
            blobs
          }
        }
        jobs {
          id
          title
        }
        applicants {
          id
          coverLetter
          job {
            status
            id
            title
            slug
            owner {
              id
              fullName
              email
            }
          }
        }
        applicableJobs {
          id
          title
          jobLocations {
            state
            country
          }
          owner {
            id
            fullName
            email
          }
          department {
            name
          }
        }
        user {
          id
          fullName
          avatarVariants
          defaultColour
        }
        owner {
          id
          fullName
          avatarVariants
          defaultColour
        }
        totalYearsOfExp
        permittedFields
        tags {
          value
          name
          id
        }
        warning
      }
      organizer {
        id
        avatarVariants
        fullName
        defaultColour
        email
      }
      attendees {
        id
        avatarVariants
        fullName
        email
        defaultColour
        roles {
          id
          name
        }
      }
      job {
        id
        title
        pitch
        description
        createdAt
        jobLocations {
          name
          address
          country
          state
          city
          zipCode
        }
        department {
          name
        }
        jobCategory {
          id
          name
        }
        skills
        educationDescription
        recruiters {
          id
        }
        owner {
          id
        }
        hiringManagers {
          id
        }
        jobRecruiters {
          user {
            id
          }
        }
        company {
          name
        }
        permittedFields
        tags {
          name
        }
      }
      applicant {
        id
        job {
          id
          title
        }
        jobStage {
          id
          stageLabel
          stageTypeId
        }
      }
      ikitFeedbacksSummary
      jobIkit {
        id
        name
        guideline
        jobIkitSessions {
          id
          name
          position
          jobIkitMetrics {
            id
            name
          }
          jobIkitQuestions {
            id
            content
          }
        }
      }
      jobStage {
        id
        stageLabel
        stageTypeId
      }
      currentUserFeedback {
        id
        name
        note
        guideline
        overallFeedback
        status
        jobIkitSessions {
          id
          name
          position
          jobIkitMetrics {
            id
            name
            score
          }
          jobIkitQuestions {
            id
            content
            answer
          }
        }
        user {
          id
          fullName
          avatarVariants
          defaultColour
        }
      }
      userFeedbacks
      pendingFeedbackUsers {
        id
        fullName
        avatarVariants
        defaultColour
      }
    }
  }
`

export default QueryInterviewFeedbackDetail
