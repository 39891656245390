import { FC, useEffect, useState } from 'react'
import {
  Control,
  Controller,
  FieldPath,
  FormState,
  UseFormClearErrors,
  UseFormSetError,
  UseFormSetValue,
  UseFormTrigger,
  useWatch
} from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import CustomField, { CustomFieldComponentType } from '~/components/CustomField'
import HTMLDisplay from '~/components/HTMLDisplay'
import configuration from '~/configuration'
import { IRouterWithID, ISelectOption } from '~/core/@types/global'
import { ROLE } from '~/core/constants/role'
import { Avatar } from '~/core/ui/Avatar'
import { LockIcon } from '~/core/ui/FillIcons'
import { FormControlItem } from '~/core/ui/FormControlItem'
import IconWrapper from '~/core/ui/IconWrapper'
import If from '~/core/ui/If'
import {
  InlineEditingEditor,
  InlineEditingInput,
  InlineEditingInputGroup
} from '~/core/ui/InlineEditing'
import {
  InlineEditingNoActionsAsyncMultipleSearchWithSelect,
  InlineEditingNoActionsAsyncSingleSearchWithSelect,
  InlineEditingNoActionsDateOrYearPicker,
  InlineEditingNoActionsNativeSelect
} from '~/core/ui/InlineEditingNoActions'
import { NativeSelect } from '~/core/ui/NativeSelect'
import { formatDatePickerToDate } from '~/core/ui/SingleDateWithYearOnlyPicker'
import { SuggestionChips } from '~/core/ui/SuggestionChips'
import { TypographyText } from '~/core/ui/Text'
import { Toggle } from '~/core/ui/Toggle'
import { Tooltip } from '~/core/ui/Tooltip'
import { getYears } from '~/core/utilities/common'
import { defaultFormatDate } from '~/core/utilities/format-date'
import {
  adminCanAction,
  limitedMemberCanAction
} from '~/core/utilities/permission'
import useCandidateProfile from '~/lib/features/candidates/hooks/use-query-candidate'
import {
  CandidateProfileInputType,
  ICandidateProfile,
  TalentPoolType
} from '~/lib/features/candidates/types'
import {
  getYearOld,
  permittedFieldsManagement,
  useConvertSalary
} from '~/lib/features/candidates/utilities'
import {
  ListSuggestNoticeOfPeriod,
  totalYoeOptions
} from '~/lib/features/candidates/utilities/enum'
import usePermissionJob from '~/lib/features/permissions/hooks/use-permission-job'
import usePromiseOwnerOptionsFetchAll from '~/lib/features/settings/members/hooks/use-promise-owner-fetch-all'
import useCustomFieldSettingByUser from '~/lib/features/settings/profile-fields/hooks/use-custom-field-setting-by-user'
import { CustomFieldFormType } from '~/lib/features/settings/profile-fields/types/custom-field'
import { getFormatRoleIds } from '~/lib/features/settings/profile-fields/utilities/common'
import {
  ADMIN_RECRUITING_RECRUITER_VALUE,
  EVERYONE_VALUE
} from '~/lib/features/settings/profile-fields/utilities/enum'
import useBoundStore from '~/lib/store'
import DisableControlByPermission from '../DisableControlByPermission'
import LanguageView from './Language/LanguageView'

const ProfileInformationView: FC<{
  applicantId?: IRouterWithID
  data: ICandidateProfile
  isLoadingUpdateProfile?: boolean
  onUpdateProfile: (
    data: CandidateProfileInputType & { paramType: string }
  ) => Promise<boolean | void>
  control: Control<any, any>
  trigger: UseFormTrigger<any>
  submit?: () => Promise<void>
  setError?: UseFormSetError<CandidateProfileInputType>
  clearErrors?: UseFormClearErrors<CandidateProfileInputType>
  submitPartialField: (
    fieldName: FieldPath<CandidateProfileInputType>,
    validate: () => Promise<boolean>,
    submit?: () => Promise<void>
  ) => Promise<boolean>
  formState: FormState<CandidateProfileInputType>
  setValue: UseFormSetValue<CandidateProfileInputType>
  configHide?: {
    [key: string]: boolean
  }
  inlineEditingOverlayEdited?: {
    [key: string]: boolean
  }
  disableSourceReferral?: boolean
}> = ({
  applicantId,
  isLoadingUpdateProfile,
  data,
  onUpdateProfile,
  control,
  trigger,
  submit,
  submitPartialField,
  formState,
  setValue,
  setError,
  clearErrors,
  configHide = {},
  inlineEditingOverlayEdited = {},
  disableSourceReferral = false
}) => {
  const { t } = useTranslation()
  const sourced = useWatch({
    control,
    name: 'sourced'
  })
  const {
    promiseSkillsOptions,
    promiseTalentPoolOptions,
    profileSourced,
    currencySalary,
    profileLevel,
    promiseProfileSourceListOptions
  } = useCandidateProfile({ sourced })
  const { numberWithCommas } = useConvertSalary()
  const { currentRole, user } = useBoundStore()
  const { canAccessTalentPool } = usePermissionJob()
  const [isShowSourceName, setIsShowSourceName] = useState(false)
  const permittedFields = data?.permittedFields

  const skillString = JSON.parse(
    JSON.stringify(permittedFields?.skills?.value || [])
  )
  const formatSkills = skillString.map((item: string) => {
    return {
      value: item,
      supportingObj: {
        name: item
      }
    }
  })
  const suggestionChipSkill = skillString.map((item: string) => {
    return {
      label: item,
      maxLength: 30
    }
  })
  const formatTalentPools = (
    data?.permittedFields?.talentPools?.value || []
  )?.map((item: TalentPoolType) => {
    return {
      value: String(item.id),
      supportingObj: {
        name: item.name
      }
    }
  })
  const suggestionChipTalentPools =
    (data?.permittedFields?.talentPools?.value || [])?.map(
      (item: TalentPoolType, index: number) => {
        return {
          id: item.id,
          label: item.name,
          classNameChip: 'prevent-clickable',
          maxLength: 25,
          onClick: () => {
            if (index !== undefined && index !== 9999) {
              window.open(
                `${configuration.path.talentPool.detail(Number(item?.id))}`,
                '_blank'
              )
            }
          }
        }
      }
    ) || []

  const promiseMembersOwnerOptionsFetchAll = usePromiseOwnerOptionsFetchAll()
  const SelectCurrentCurrency = () => {
    return (
      <div>
        <Controller
          control={control}
          name="currentSalaryCurrency"
          render={({ field: { onChange, value } }) => {
            const findSalary = currencySalary.find(
              (item: ISelectOption) =>
                item.value === (value || user?.currentTenant?.currency)
            )
            const formatCurrentSalaryCurrency = findSalary
              ? {
                  value: value || user?.currentTenant?.currency,
                  supportingObj: {
                    name: findSalary?.supportingObj?.name || ''
                  }
                }
              : undefined

            return (
              <FormControlItem>
                <NativeSelect
                  size="sm"
                  isClearable={false}
                  isSearchable={false}
                  onChange={(newValue) => {
                    onChange((newValue as ISelectOption).value)
                  }}
                  value={formatCurrentSalaryCurrency}
                  options={currencySalary}
                  classNameOverride={{
                    bordered: 'none',
                    loadingMessage: `${t('label:loading')}`,
                    noOptionsMessage: `${t('label:noOptions')}`
                  }}
                  menuPosition="fixed"
                />
              </FormControlItem>
            )
          }}
        />
      </div>
    )
  }

  const SelectExpertCurrency = () => {
    return (
      <div>
        <Controller
          control={control}
          name="expectedSalaryCurrency"
          render={({ field: { onChange, value } }) => {
            const findSalary = currencySalary.find(
              (item: ISelectOption) =>
                item.value === (value || user?.currentTenant?.currency)
            )
            const formatExpectedSalaryCurrency = findSalary
              ? {
                  value: value || user?.currentTenant?.currency,
                  supportingObj: {
                    name: findSalary?.supportingObj?.name || ''
                  }
                }
              : undefined

            return (
              <FormControlItem>
                <NativeSelect
                  size="sm"
                  isClearable={false}
                  isSearchable={false}
                  onChange={(newValue) => {
                    onChange((newValue as ISelectOption).value)
                    return submitPartialField(
                      'expectedSalaryCurrency',
                      trigger,
                      submit
                    )
                  }}
                  value={formatExpectedSalaryCurrency}
                  options={currencySalary}
                  classNameOverride={{
                    bordered: 'none',
                    loadingMessage: `${t('label:loading')}`,
                    noOptionsMessage: `${t('label:noOptions')}`
                  }}
                  menuPosition="fixed"
                />
              </FormControlItem>
            )
          }}
        />
      </div>
    )
  }
  const { data: customFieldViewData } = useCustomFieldSettingByUser({
    objectKind: 'profile'
  })
  useEffect(() => {
    if (sourced) {
      if (!data.sourcedNameDescription) {
        setValue('sourcedName', '')
      }
      promiseProfileSourceListOptions({ page: 1, limit: 25 }).then((rs) => {
        if (rs.collection.length > 0) {
          setIsShowSourceName(true)
        } else setIsShowSourceName(false)
      })
    }
  }, [sourced])

  return (
    <div className="grid grid-cols-[108px_1fr] desktop:grid-cols-[160px_1fr]">
      {!configHide.headline ? (
        <>
          <If
            condition={
              permittedFieldsManagement(permittedFields?.headline?.roles) ||
              permittedFields?.headline
            }>
            <div className="flex items-center space-x-2">
              <IconWrapper
                size={16}
                name="Newspaper"
                className="hidden text-gray-500 desktop:block"
              />
              <TypographyText className="text-sm text-gray-600">
                {t(
                  'candidates:tabs:candidateOverview:profileInformation:position'
                )}
              </TypographyText>
              <If
                condition={
                  adminCanAction(currentRole?.code) &&
                  !permittedFields?.headline?.roles?.includes(ROLE.Everyone) &&
                  !permittedFields?.headline?.client_user_visibility
                }>
                <Tooltip
                  classNameConfig={{ content: 'max-w-full' }}
                  content={t('candidates:tabs:candidateOverview:visibleTo', {
                    role: permittedFields?.headline?.roles?.join(', ')
                  })}>
                  <LockIcon />
                </Tooltip>
              </If>
            </div>

            <div className="w-full">
              <Controller
                control={control}
                name="headline"
                render={({ field: { onChange, value } }) => {
                  return (
                    <FormControlItem>
                      <DisableControlByPermission
                        applicantId={applicantId}
                        text={
                          <div className="px-2 py-1.5">
                            {value ? (
                              <Tooltip content={value} align="start">
                                <div className="line-clamp-1 text-sm text-gray-900">
                                  {value}
                                </div>
                              </Tooltip>
                            ) : (
                              <div className="line-clamp-1 text-sm text-gray-500">
                                {t(
                                  'candidates:tabs:candidateOverview:notAvailable'
                                )}
                              </div>
                            )}
                          </div>
                        }>
                        <InlineEditingInput
                          onChange={(newValue) => {
                            onChange(newValue)
                            return submitPartialField(
                              'headline',
                              trigger,
                              submit
                            )
                          }}
                          onCancelUpdate={onChange}
                          placeholder={`${t(
                            'candidates:tabs:candidateOverview:profileInformation:add_position'
                          )}`}
                          size="sm"
                          className="-mx-px px-2"
                          value={value}
                          destructiveText={
                            formState?.errors?.headline?.message as string
                          }
                          tooltipActionCancel={{
                            title: `${t('button:cancel')}`
                          }}
                          tooltipActionSave={{
                            title: `${t('button:saveEnter')}`
                          }}
                          tooltipElement={{
                            title: `${
                              (formState.errors?.headline?.message as string) ||
                              ''
                            }`
                          }}>
                          <div className="px-2 py-1.5">
                            {value ? (
                              <Tooltip content={value} align="start">
                                <div className="line-clamp-1 text-sm text-gray-900">
                                  {value}
                                </div>
                              </Tooltip>
                            ) : (
                              <div className="line-clamp-1 text-sm text-gray-500">
                                {t(
                                  'candidates:tabs:candidateOverview:profileInformation:add_position'
                                )}
                              </div>
                            )}
                          </div>
                        </InlineEditingInput>
                      </DisableControlByPermission>
                    </FormControlItem>
                  )
                }}
              />
            </div>
          </If>
        </>
      ) : null}
      {!configHide.openToWork ? (
        <>
          <If
            condition={
              permittedFieldsManagement(permittedFields?.openToWork?.roles) ||
              permittedFields?.openToWork
            }>
            <div className="flex items-center space-x-2">
              <IconWrapper
                size={16}
                name="Briefcase"
                className="hidden text-gray-500 desktop:block"
              />
              <TypographyText className="text-sm text-gray-600">
                {t(
                  'candidates:tabs:candidateOverview:profileInformation:openToWork'
                )}
              </TypographyText>
              <If
                condition={
                  adminCanAction(currentRole?.code) &&
                  !permittedFields?.openToWork?.roles?.includes(
                    ROLE.Everyone
                  ) &&
                  !permittedFields?.openToWork?.client_user_visibility
                }>
                <Tooltip
                  classNameConfig={{ content: 'max-w-full' }}
                  content={t('candidates:tabs:candidateOverview:visibleTo', {
                    role: permittedFields?.openToWork?.roles?.join(', ')
                  })}>
                  <LockIcon />
                </Tooltip>
              </If>
            </div>

            <div className="flex w-full px-2 py-1.5">
              <Controller
                control={control}
                name="openToWork"
                render={({ field: { onChange, value } }) => {
                  return (
                    <FormControlItem>
                      <DisableControlByPermission
                        applicantId={applicantId}
                        text={
                          <Toggle
                            name="openToWork"
                            isChecked={value}
                            isDisabled
                            size="sm"
                          />
                        }>
                        <Toggle
                          name="openToWork"
                          isChecked={value}
                          onCheckedChange={(checked) => {
                            onChange(checked)
                            return submitPartialField(
                              'openToWork',
                              trigger,
                              submit
                            )
                          }}
                          size="sm"
                        />
                      </DisableControlByPermission>
                    </FormControlItem>
                  )
                }}
              />
            </div>
          </If>
        </>
      ) : null}
      {!configHide.talentPools && canAccessTalentPool ? (
        <>
          <If
            condition={
              permittedFieldsManagement(permittedFields?.talentPools?.roles) ||
              permittedFields?.talentPools
            }>
            <div className="flex items-center space-x-2">
              <IconWrapper
                size={16}
                name="FolderSearch"
                className="hidden text-gray-500 desktop:block"
              />
              <TypographyText className="text-sm text-gray-600">
                {t(
                  'candidates:tabs:candidateOverview:profileInformation:talentPools'
                )}
              </TypographyText>
            </div>

            <div className="w-full">
              <Controller
                control={control}
                name="profileTalentPoolIds"
                render={({ field: { onChange, value } }) => {
                  return (
                    <FormControlItem>
                      <DisableControlByPermission
                        applicantId={applicantId}
                        text={
                          <div className="flex items-center px-2 py-1.5 text-sm">
                            {value && value.length > 0 ? (
                              <div className="text-gray-900">
                                <SuggestionChips
                                  size="sm"
                                  source={suggestionChipTalentPools}
                                  type="default"
                                />
                              </div>
                            ) : (
                              <div className="text-gray-500">
                                {t(
                                  'candidates:tabs:candidateOverview:notAvailable'
                                )}
                              </div>
                            )}
                          </div>
                        }>
                        <InlineEditingNoActionsAsyncMultipleSearchWithSelect
                          overlayEdited={
                            !!inlineEditingOverlayEdited.talentPools
                          }
                          onChange={(newValue) => {
                            const talentPoolsList =
                              newValue.length > 0
                                ? newValue.map((item) => item?.value)
                                : []
                            onChange(talentPoolsList)
                            return submitPartialField(
                              'profileTalentPoolIds',
                              trigger,
                              submit
                            )
                          }}
                          configSelectOption={{
                            option: 'checkbox',
                            supportingText: ['name']
                          }}
                          classNameOverride={{
                            loadingMessage: `${t('label:loading')}`,
                            noOptionsMessage: `${t('label:noOptions')}`
                          }}
                          onCancelUpdate={onChange}
                          promiseOptions={promiseTalentPoolOptions}
                          placeholder={`${t(
                            'candidates:tabs:candidateOverview:profileInformation:addTalentPool'
                          )}`}
                          size="sm"
                          value={formatTalentPools}>
                          <div className="flex items-center px-2 py-1.5 text-sm">
                            {value && value.length > 0 ? (
                              <div className="text-gray-900">
                                <SuggestionChips
                                  size="sm"
                                  source={suggestionChipTalentPools}
                                  type="default"
                                />
                              </div>
                            ) : (
                              <div className="text-gray-500">
                                {t(
                                  'candidates:tabs:candidateOverview:profileInformation:addTalentPool'
                                )}
                              </div>
                            )}
                          </div>
                        </InlineEditingNoActionsAsyncMultipleSearchWithSelect>
                      </DisableControlByPermission>
                    </FormControlItem>
                  )
                }}
              />
            </div>
          </If>
        </>
      ) : null}
      {!configHide.profileLevel ? (
        <>
          <If
            condition={
              permittedFieldsManagement(permittedFields?.profileLevel?.roles) ||
              permittedFields?.profileLevel
            }>
            <div className="flex items-center space-x-2">
              <IconWrapper
                size={16}
                name="Boxes"
                className="hidden text-gray-500 desktop:block"
              />
              <TypographyText className="text-sm text-gray-600">
                {t(
                  'candidates:tabs:candidateOverview:profileInformation:experienceLevel'
                )}
              </TypographyText>
              <If
                condition={
                  adminCanAction(currentRole?.code) &&
                  !permittedFields?.profileLevel?.roles?.includes(
                    ROLE.Everyone
                  ) &&
                  !permittedFields?.profileLevel?.client_user_visibility
                }>
                <Tooltip
                  classNameConfig={{ content: 'max-w-full' }}
                  content={t('candidates:tabs:candidateOverview:visibleTo', {
                    role: permittedFields?.profileLevel?.roles?.join(', ')
                  })}>
                  <LockIcon />
                </Tooltip>
              </If>
            </div>

            <div className="w-full">
              <Controller
                control={control}
                name="profileLevel"
                render={({ field: { onChange, value } }) => {
                  const findProfileLevel = profileLevel.find(
                    (item: ISelectOption) =>
                      String(item.value) === String(value)
                  )
                  const formatProfileLevel = findProfileLevel
                    ? {
                        value: value,
                        supportingObj: {
                          name: findProfileLevel?.supportingObj?.name || ''
                        }
                      }
                    : undefined

                  return (
                    <FormControlItem>
                      <DisableControlByPermission
                        applicantId={applicantId}
                        text={
                          <div className="flex items-center px-2 py-1.5 text-sm">
                            {value ? (
                              <div className="text-gray-900">
                                {formatProfileLevel?.supportingObj?.name}
                              </div>
                            ) : (
                              <div className="text-gray-500">
                                {t(
                                  'candidates:tabs:candidateOverview:notAvailable'
                                )}
                              </div>
                            )}
                          </div>
                        }>
                        <InlineEditingNoActionsNativeSelect
                          onChange={(newValue) => {
                            onChange(newValue?.value || null)
                            return submitPartialField(
                              'profileLevel',
                              trigger,
                              submit
                            )
                          }}
                          onCancelUpdate={(newValue) =>
                            onChange(newValue?.value)
                          }
                          options={profileLevel}
                          placeholder={
                            t(
                              'candidates:tabs:candidateOverview:profileInformation:selectExperienceLevel'
                            ) as string
                          }
                          size="sm"
                          classNameOverride={{
                            loadingMessage: `${t('label:loading')}`,
                            noOptionsMessage: `${t('label:noOptions')}`
                          }}
                          value={formatProfileLevel}>
                          <div className="flex items-center px-2 py-1.5 text-sm">
                            {value ? (
                              <div className="text-gray-900">
                                {formatProfileLevel?.supportingObj?.name}
                              </div>
                            ) : (
                              <div className="text-gray-500">
                                {t(
                                  'candidates:tabs:candidateOverview:profileInformation:selectExperienceLevel'
                                )}
                              </div>
                            )}
                          </div>
                        </InlineEditingNoActionsNativeSelect>
                      </DisableControlByPermission>
                    </FormControlItem>
                  )
                }}
              />
            </div>
          </If>
        </>
      ) : null}
      {!configHide.totalYearsOfExp ? (
        <>
          <If
            condition={
              permittedFieldsManagement(
                permittedFields?.totalYearsOfExp?.roles
              ) || permittedFields?.totalYearsOfExp
            }>
            <div className="flex items-center space-x-2">
              <IconWrapper
                size={16}
                name="Boxes"
                className="hidden text-gray-500 desktop:block"
              />
              <TypographyText className="text-sm text-gray-600">
                {t(
                  'candidates:tabs:candidateOverview:profileInformation:totalYoe'
                )}
              </TypographyText>
              <If
                condition={
                  adminCanAction(currentRole?.code) &&
                  !permittedFields?.totalYearsOfExp?.roles?.includes(
                    ROLE.Everyone
                  ) &&
                  !permittedFields?.totalYearsOfExp?.client_user_visibility
                }>
                <Tooltip
                  classNameConfig={{ content: 'max-w-full' }}
                  content={t('candidates:tabs:candidateOverview:visibleTo', {
                    role: permittedFields?.totalYearsOfExp?.roles?.join(', ')
                  })}>
                  <LockIcon />
                </Tooltip>
              </If>
            </div>

            <div className="w-full">
              <Controller
                control={control}
                name="totalYearsOfExp"
                render={({ field: { onChange, value } }) => {
                  const checked = ['number', 'string'].includes(typeof value)
                  const formatTotalYearOfExp = checked
                    ? {
                        value: String(value),
                        supportingObj: {
                          name:
                            totalYoeOptions
                              .map((item) => ({
                                ...item,
                                supportingObj: {
                                  name: `${t(
                                    `candidates:yoeOptions:${item.value}`
                                  )}`
                                }
                              }))
                              .find(
                                (item: ISelectOption) =>
                                  item.value === String(value)
                              )?.supportingObj?.name || ''
                        }
                      }
                    : undefined

                  return (
                    <FormControlItem>
                      <DisableControlByPermission
                        applicantId={applicantId}
                        text={
                          <div className="flex items-center px-2 py-1.5 text-sm">
                            {checked ? (
                              <div className="text-gray-900">
                                {
                                  totalYoeOptions
                                    .map((item) => ({
                                      ...item,
                                      supportingObj: {
                                        name: `${t(
                                          `candidates:yoeOptions:${item.value}`
                                        )}`
                                      }
                                    }))
                                    .find(
                                      (item) => item.value === String(value)
                                    )?.supportingObj?.name
                                }
                              </div>
                            ) : (
                              <div className="text-gray-500">
                                {t(
                                  'candidates:tabs:candidateOverview:notAvailable'
                                )}
                              </div>
                            )}
                          </div>
                        }>
                        <InlineEditingNoActionsNativeSelect
                          onChange={(newValue) => {
                            onChange(
                              newValue?.value !== undefined
                                ? Number(newValue?.value)
                                : null
                            )
                            return submitPartialField(
                              'totalYearsOfExp',
                              trigger,
                              submit
                            )
                          }}
                          onCancelUpdate={(newValue) => {
                            onChange(newValue?.value)
                          }}
                          options={totalYoeOptions.map((item) => ({
                            ...item,
                            supportingObj: {
                              name: `${t(
                                `candidates:yoeOptions:${item.value}`
                              )}`
                            }
                          }))}
                          placeholder={
                            t(
                              'candidates:tabs:candidateOverview:profileInformation:selectTotalYearOfExp'
                            ) as string
                          }
                          size="sm"
                          classNameOverride={{
                            loadingMessage: `${t('label:loading')}`,
                            noOptionsMessage: `${t('label:noOptions')}`
                          }}
                          value={formatTotalYearOfExp}>
                          <div className="flex items-center px-2 py-1.5 text-sm">
                            {checked ? (
                              <div className="text-gray-900">
                                {
                                  totalYoeOptions
                                    .map((item) => ({
                                      ...item,
                                      supportingObj: {
                                        name: `${t(
                                          `candidates:yoeOptions:${item.value}`
                                        )}`
                                      }
                                    }))
                                    .find(
                                      (item) => item.value === String(value)
                                    )?.supportingObj?.name
                                }
                              </div>
                            ) : (
                              <div className="text-gray-500">
                                {t(
                                  'candidates:tabs:candidateOverview:profileInformation:selectTotalYearOfExp'
                                )}
                              </div>
                            )}
                          </div>
                        </InlineEditingNoActionsNativeSelect>
                      </DisableControlByPermission>
                    </FormControlItem>
                  )
                }}
              />
            </div>
          </If>
        </>
      ) : null}
      {!configHide.skills ? (
        <>
          <If
            condition={
              permittedFieldsManagement(permittedFields?.skills?.roles) ||
              permittedFields?.skills
            }>
            <div>
              <div className="flex w-full items-center space-x-2 py-1.5">
                <IconWrapper
                  size={16}
                  name="ListChecks"
                  className="hidden text-gray-500 desktop:block"
                />
                <TypographyText className="text-sm text-gray-600">
                  {t(
                    'candidates:tabs:candidateOverview:profileInformation:skills'
                  )}
                </TypographyText>
                <If
                  condition={
                    adminCanAction(currentRole?.code) &&
                    !permittedFields?.skills?.roles?.includes(ROLE.Everyone) &&
                    !permittedFields?.skills?.client_user_visibility
                  }>
                  <Tooltip
                    classNameConfig={{ content: 'max-w-full' }}
                    content={t('candidates:tabs:candidateOverview:visibleTo', {
                      role: permittedFields?.skills?.roles?.join(', ')
                    })}>
                    <LockIcon />
                  </Tooltip>
                </If>
              </div>
            </div>

            <div className="w-full">
              <Controller
                control={control}
                name="skills"
                render={({ field: { onChange, value } }) => {
                  return (
                    <FormControlItem>
                      <DisableControlByPermission
                        applicantId={applicantId}
                        text={
                          <div className="flex items-center px-2 py-1.5 text-sm">
                            {value && value.length > 0 ? (
                              <div className="text-gray-900">
                                <SuggestionChips
                                  size="sm"
                                  source={suggestionChipSkill}
                                  type="default"
                                />
                              </div>
                            ) : (
                              <div className="text-gray-500">
                                {t(
                                  'candidates:tabs:candidateOverview:notAvailable'
                                )}
                              </div>
                            )}
                          </div>
                        }>
                        <InlineEditingNoActionsAsyncMultipleSearchWithSelect
                          overlayEdited={!!inlineEditingOverlayEdited.skills}
                          creatable
                          onChange={(newValue) => {
                            const skillsList =
                              newValue.length > 0
                                ? newValue.map((item) =>
                                    item?.__isNew__
                                      ? item.label
                                      : item.supportingObj?.name
                                  )
                                : []
                            onChange(skillsList)

                            return submitPartialField('skills', trigger, submit)
                          }}
                          destructiveText={
                            formState?.errors?.skills?.message as string
                          }
                          configSelectOption={{
                            option: 'checkbox'
                          }}
                          classNameOverride={{
                            loadingMessage: `${t('label:loading')}`,
                            noOptionsMessage: `${t('label:noOptions')}`
                          }}
                          onInputChange={(inputChange) => {
                            if ((inputChange || '').length > 0)
                              if ((inputChange || '').length > 30) {
                                setError &&
                                  setError('skills', {
                                    type: 'invalid_input_search',
                                    message: `${t(
                                      'form:field_max_number_required',
                                      { number: 30 }
                                    )}`
                                  })
                              } else {
                                clearErrors && clearErrors()
                                trigger()
                              }
                          }}
                          isValidNewOption={(inputChange) =>
                            (inputChange || '').length <= 30
                          }
                          callbackClearSearchData={() => {
                            clearErrors && clearErrors()
                            trigger()
                          }}
                          onCancelUpdate={onChange}
                          promiseOptions={promiseSkillsOptions}
                          placeholder={
                            t(
                              'candidates:tabs:candidateOverview:profileInformation:addSkills'
                            ) as string
                          }
                          size="sm"
                          value={formatSkills}>
                          <div className="flex items-center px-2 py-1.5 text-sm">
                            {value && value.length > 0 ? (
                              <div className="text-gray-900">
                                <SuggestionChips
                                  size="sm"
                                  source={suggestionChipSkill}
                                  type="default"
                                />
                              </div>
                            ) : (
                              <div className="text-gray-500">
                                {t(
                                  'candidates:tabs:candidateOverview:profileInformation:addSkills'
                                )}
                              </div>
                            )}
                          </div>
                        </InlineEditingNoActionsAsyncMultipleSearchWithSelect>
                      </DisableControlByPermission>
                    </FormControlItem>
                  )
                }}
              />
            </div>
          </If>
        </>
      ) : null}
      {!configHide.language ? (
        <>
          <If
            condition={
              permittedFieldsManagement(permittedFields?.languages?.roles) ||
              permittedFields?.languages
            }>
            <div>
              <div className="flex w-full items-center space-x-2 py-1.5">
                <IconWrapper
                  size={16}
                  name="Languages"
                  className="hidden text-gray-500 desktop:block"
                />
                <TypographyText className="text-sm text-gray-600">
                  {t(
                    'candidates:tabs:candidateOverview:profileInformation:languages'
                  )}
                </TypographyText>
                <If
                  condition={
                    adminCanAction(currentRole?.code) &&
                    !permittedFields?.languages?.roles?.includes(
                      ROLE.Everyone
                    ) &&
                    !permittedFields?.languages?.client_user_visibility
                  }>
                  <Tooltip
                    classNameConfig={{ content: 'max-w-full' }}
                    content={t('candidates:tabs:candidateOverview:visibleTo', {
                      role: permittedFields?.languages?.roles?.join(', ')
                    })}>
                    <LockIcon />
                  </Tooltip>
                </If>
              </div>
            </div>

            <div className="px-2 py-1.5">
              <LanguageView
                applicantId={applicantId}
                onUpdateProfile={onUpdateProfile}
                data={data}
              />
            </div>
          </If>
        </>
      ) : null}
      {!configHide.currentSalary ? (
        <>
          <If
            condition={
              permittedFieldsManagement(
                permittedFields?.currentSalary?.roles
              ) || permittedFields?.currentSalary
            }>
            <div className="flex w-full items-center space-x-2">
              <IconWrapper
                size={16}
                name="DollarSign"
                className="hidden text-gray-500 desktop:block"
              />
              <TypographyText className="text-sm text-gray-600">
                {t(
                  'candidates:tabs:candidateOverview:profileInformation:currentSalary'
                )}
              </TypographyText>
              <If
                condition={
                  adminCanAction(currentRole?.code) &&
                  !permittedFields?.currentSalary?.roles?.includes(
                    ROLE.Everyone
                  ) &&
                  !permittedFields?.currentSalary?.client_user_visibility
                }>
                <Tooltip
                  classNameConfig={{ content: 'max-w-full' }}
                  content={t('candidates:tabs:candidateOverview:visibleTo', {
                    role: permittedFields?.currentSalary?.roles?.join(', ')
                  })}>
                  <LockIcon />
                </Tooltip>
              </If>
            </div>

            <div className="w-full">
              <Controller
                control={control}
                name="currentSalary"
                render={({ field: { onChange, value } }) => {
                  return (
                    <FormControlItem>
                      <DisableControlByPermission
                        applicantId={applicantId}
                        text={
                          <div className="flex items-center px-2 py-1.5 text-sm">
                            {value ? (
                              <div className="text-gray-900">
                                {numberWithCommas(value)}{' '}
                                {permittedFields?.currentSalaryCurrency
                                  ?.value || user?.currentTenant?.currency}
                              </div>
                            ) : (
                              <div className="text-gray-500">
                                {t(
                                  'candidates:tabs:candidateOverview:notAvailable'
                                )}
                              </div>
                            )}
                          </div>
                        }>
                        <InlineEditingInputGroup
                          onChange={(newValue) => {
                            onChange(Number(newValue))
                            return submitPartialField(
                              'currentSalary',
                              trigger,
                              submit
                            )
                          }}
                          onCancelUpdate={onChange}
                          renderedElement={SelectCurrentCurrency()}
                          placeholder={t(
                            'candidates:tabs:candidateOverview:profileInformation:addCurrentSalary'
                          ).toString()}
                          size="sm"
                          value={value}
                          tooltipActionCancel={{
                            title: `${t('button:cancel')}`
                          }}
                          tooltipActionSave={{
                            title: `${t('button:saveEnter')}`
                          }}
                          destructiveText={
                            formState?.errors?.currentSalary?.message as string
                          }>
                          <div className="flex items-center px-2 py-1.5 text-sm">
                            {value ? (
                              <div className="text-gray-900">
                                {numberWithCommas(value)}{' '}
                                {permittedFields?.currentSalaryCurrency
                                  ?.value || user?.currentTenant?.currency}
                              </div>
                            ) : (
                              <div className="text-gray-500">
                                {t(
                                  'candidates:tabs:candidateOverview:profileInformation:addCurrentSalary'
                                )}
                              </div>
                            )}
                          </div>
                        </InlineEditingInputGroup>
                      </DisableControlByPermission>
                    </FormControlItem>
                  )
                }}
              />
            </div>
          </If>
        </>
      ) : null}
      {!configHide.expectedSalary ? (
        <>
          <If
            condition={
              permittedFieldsManagement(
                permittedFields?.expectedSalary?.roles
              ) || permittedFields?.expectedSalary
            }>
            <div className="flex w-full items-center space-x-2">
              <IconWrapper
                size={16}
                name="DollarSign"
                className="hidden text-gray-500 desktop:block"
              />
              <TypographyText className="text-sm text-gray-600">
                {t(
                  'candidates:tabs:candidateOverview:profileInformation:expectedSalary'
                )}
              </TypographyText>
              <If
                condition={
                  adminCanAction(currentRole?.code) &&
                  !permittedFields?.expectedSalary?.roles?.includes(
                    ROLE.Everyone
                  ) &&
                  !permittedFields?.expectedSalary?.client_user_visibility
                }>
                <Tooltip
                  classNameConfig={{ content: 'max-w-full' }}
                  content={t('candidates:tabs:candidateOverview:visibleTo', {
                    role: permittedFields?.expectedSalary?.roles?.join(', ')
                  })}>
                  <LockIcon />
                </Tooltip>
              </If>
            </div>

            <div className="w-full">
              <Controller
                control={control}
                name="expectedSalary"
                render={({ field: { onChange, value } }) => {
                  return (
                    <FormControlItem>
                      <DisableControlByPermission
                        applicantId={applicantId}
                        text={
                          <div className="flex items-center px-2 py-1.5 text-sm">
                            {value ? (
                              <div className="text-gray-900">
                                {numberWithCommas(value)}{' '}
                                {permittedFields?.expectedSalaryCurrency
                                  ?.value || user?.currentTenant?.currency}
                              </div>
                            ) : (
                              <div className="text-gray-500">
                                {t(
                                  'candidates:tabs:candidateOverview:notAvailable'
                                )}
                              </div>
                            )}
                          </div>
                        }>
                        <InlineEditingInputGroup
                          onChange={(newValue) => {
                            onChange(Number(newValue))
                            return submitPartialField(
                              'expectedSalary',
                              trigger,
                              submit
                            )
                          }}
                          onCancelUpdate={onChange}
                          renderedElement={SelectExpertCurrency()}
                          placeholder={
                            t(
                              'candidates:tabs:candidateOverview:profileInformation:addExpectedSalary'
                            ) as string
                          }
                          size="sm"
                          value={value}
                          tooltipActionCancel={{
                            title: `${t('button:cancel')}`
                          }}
                          tooltipActionSave={{
                            title: `${t('button:saveEnter')}`
                          }}
                          destructiveText={
                            formState?.errors?.expectedSalary?.message as string
                          }>
                          <div className="flex items-center px-2 py-1.5 text-sm">
                            {value ? (
                              <div className="text-gray-900">
                                {numberWithCommas(value)}{' '}
                                {permittedFields?.expectedSalaryCurrency
                                  ?.value || user?.currentTenant?.currency}
                              </div>
                            ) : (
                              <div className="text-gray-500">
                                {t(
                                  'candidates:tabs:candidateOverview:profileInformation:addExpectedSalary'
                                )}
                              </div>
                            )}
                          </div>
                        </InlineEditingInputGroup>
                      </DisableControlByPermission>
                    </FormControlItem>
                  )
                }}
              />
            </div>
          </If>
        </>
      ) : null}
      {!configHide.birthday ? (
        <>
          <If
            condition={
              permittedFieldsManagement(permittedFields?.birthday?.roles) ||
              permittedFields?.birthday
            }>
            <div className="flex items-center space-x-2">
              <IconWrapper
                size={16}
                name="Cake"
                className="hidden text-gray-500 desktop:block"
              />
              <TypographyText className="text-sm text-gray-600">
                {t(
                  'candidates:tabs:candidateOverview:profileInformation:birthday'
                )}
              </TypographyText>
            </div>
            <div className="w-full">
              <Controller
                control={control}
                name="birthday"
                render={({ field: { onChange, value } }) => {
                  return (
                    <FormControlItem>
                      <DisableControlByPermission
                        applicantId={applicantId}
                        text={
                          <div className="flex items-center px-2 py-1.5 text-sm">
                            {value?.year ? (
                              <div className="text-gray-900">
                                {!!value?.year &&
                                !value.month &&
                                !value.date ? (
                                  <Trans
                                    i18nKey="candidates:tabs:candidateOverview:profileInformation:birthday_years_old"
                                    values={{
                                      date: value.year,
                                      years_old: getYearOld(
                                        new Date(`${value.year}-01-01T00:00:00`)
                                      )
                                    }}>
                                    <span className="text-gray-500" />
                                  </Trans>
                                ) : (
                                  <Trans
                                    i18nKey="candidates:tabs:candidateOverview:profileInformation:birthday_years_old"
                                    values={{
                                      date: defaultFormatDate(
                                        formatDatePickerToDate(value)
                                      ),
                                      years_old: getYearOld(
                                        formatDatePickerToDate(value)
                                      )
                                    }}>
                                    <span className="text-gray-500" />
                                  </Trans>
                                )}
                              </div>
                            ) : (
                              <div className="text-gray-500">
                                {t(
                                  'candidates:tabs:candidateOverview:notAvailable'
                                )}
                              </div>
                            )}
                          </div>
                        }>
                        <InlineEditingNoActionsDateOrYearPicker
                          onChange={(newValue) => {
                            onChange(newValue)
                            return submitPartialField(
                              'birthday',
                              trigger,
                              submit
                            )
                          }}
                          onCancelUpdate={(newValue) => onChange(newValue)}
                          placeholder={
                            t(
                              'candidates:tabs:candidateOverview:profileInformation:selectBirthday'
                            ) as string
                          }
                          value={value}>
                          <div className="flex items-center px-2 py-1.5 text-sm">
                            {value?.year ? (
                              <div className="text-gray-900">
                                {!!value?.year &&
                                !value.month &&
                                !value.date ? (
                                  <Trans
                                    i18nKey="candidates:tabs:candidateOverview:profileInformation:birthday_years_old"
                                    values={{
                                      date: value.year,
                                      years_old: getYearOld(
                                        new Date(`${value.year}-01-01T00:00:00`)
                                      )
                                    }}>
                                    <span className="text-gray-500" />
                                  </Trans>
                                ) : (
                                  <Trans
                                    i18nKey="candidates:tabs:candidateOverview:profileInformation:birthday_years_old"
                                    values={{
                                      date: defaultFormatDate(
                                        formatDatePickerToDate(value)
                                      ),
                                      years_old: getYearOld(
                                        formatDatePickerToDate(value)
                                      )
                                    }}>
                                    <span className="text-gray-500" />
                                  </Trans>
                                )}
                              </div>
                            ) : (
                              <div className="text-gray-500">
                                {t(
                                  'candidates:tabs:candidateOverview:profileInformation:selectBirthday'
                                )}
                              </div>
                            )}
                          </div>
                        </InlineEditingNoActionsDateOrYearPicker>
                      </DisableControlByPermission>
                    </FormControlItem>
                  )
                }}
              />
            </div>
          </If>
        </>
      ) : null}
      {!configHide.nationality ? (
        <>
          <If
            condition={
              permittedFieldsManagement(permittedFields?.nationality?.roles) ||
              permittedFields?.nationality
            }>
            <div className="flex w-full items-center space-x-2">
              <IconWrapper
                size={16}
                name="Flag"
                className="hidden text-gray-500 desktop:block"
              />
              <TypographyText className="text-sm text-gray-600">
                {t(
                  'candidates:tabs:candidateOverview:profileInformation:nationality'
                )}
              </TypographyText>
              <If
                condition={
                  adminCanAction(currentRole?.code) &&
                  !permittedFields?.nationality?.roles?.includes(
                    ROLE.Everyone
                  ) &&
                  !permittedFields?.nationality?.client_user_visibility
                }>
                <Tooltip
                  classNameConfig={{ content: 'max-w-full' }}
                  content={t('candidates:tabs:candidateOverview:visibleTo', {
                    role: permittedFields?.nationality?.roles?.join(', ')
                  })}>
                  <LockIcon />
                </Tooltip>
              </If>
            </div>

            <div className="w-full">
              <Controller
                control={control}
                name="nationality"
                render={({ field: { onChange, value } }) => {
                  return (
                    <FormControlItem>
                      <DisableControlByPermission
                        applicantId={applicantId}
                        text={
                          <div className="flex items-center px-2 py-1.5 text-sm">
                            {value ? (
                              <div className="text-gray-900">{value}</div>
                            ) : (
                              <div className="text-gray-500">
                                {t(
                                  'candidates:tabs:candidateOverview:notAvailable'
                                )}
                              </div>
                            )}
                          </div>
                        }>
                        <InlineEditingInput
                          onChange={(newValue) => {
                            onChange(newValue)
                            return submitPartialField(
                              'nationality',
                              trigger,
                              submit
                            )
                          }}
                          onCancelUpdate={onChange}
                          placeholder={
                            t(
                              'candidates:tabs:candidateOverview:profileInformation:selectCountry'
                            ) as string
                          }
                          size="sm"
                          value={value}
                          tooltipActionCancel={{
                            title: `${t('button:cancel')}`
                          }}
                          tooltipActionSave={{
                            title: `${t('button:saveEnter')}`
                          }}>
                          <div className="flex items-center px-2 py-1.5 text-sm">
                            {value ? (
                              <div className="text-gray-900">{value}</div>
                            ) : (
                              <div className="text-gray-500">
                                {t(
                                  'candidates:tabs:candidateOverview:profileInformation:selectCountry'
                                )}
                              </div>
                            )}
                          </div>
                        </InlineEditingInput>
                      </DisableControlByPermission>
                    </FormControlItem>
                  )
                }}
              />
            </div>
          </If>
        </>
      ) : null}
      {!configHide.willingToRelocate ? (
        <>
          <If
            condition={
              permittedFieldsManagement(
                permittedFields?.willingToRelocate?.roles
              ) || permittedFields?.willingToRelocate
            }>
            <div className="flex w-full items-center space-x-2">
              <IconWrapper
                size={16}
                name="Rotate3d"
                className="hidden text-gray-500 desktop:block"
              />
              <TypographyText className="text-sm text-gray-600">
                {t(
                  'candidates:tabs:candidateOverview:profileInformation:willingToRelocate'
                )}
              </TypographyText>
              <If
                condition={
                  adminCanAction(currentRole?.code) &&
                  !permittedFields?.willingToRelocate?.roles?.includes(
                    ROLE.Everyone
                  ) &&
                  !permittedFields?.willingToRelocate?.client_user_visibility
                }>
                <Tooltip
                  classNameConfig={{ content: 'max-w-full' }}
                  content={t('candidates:tabs:candidateOverview:visibleTo', {
                    role: permittedFields?.willingToRelocate?.roles?.join(', ')
                  })}>
                  <LockIcon />
                </Tooltip>
              </If>
            </div>

            <div className="flex w-full px-2 py-1.5">
              <Controller
                control={control}
                name="willingToRelocate"
                render={({ field: { onChange, value } }) => {
                  return (
                    <FormControlItem>
                      <DisableControlByPermission
                        applicantId={applicantId}
                        text={
                          <Toggle
                            name="willingToRelocate"
                            isChecked={value}
                            isDisabled
                            size="sm"
                          />
                        }>
                        <Toggle
                          name="willingToRelocate"
                          isChecked={value}
                          onCheckedChange={(checked) => {
                            onChange(checked)
                            return submitPartialField(
                              'willingToRelocate',
                              trigger,
                              submit
                            )
                          }}
                          size="sm"
                        />
                      </DisableControlByPermission>
                    </FormControlItem>
                  )
                }}
              />
            </div>
          </If>
        </>
      ) : null}
      {!configHide.noticeToPeriodDays ? (
        <>
          <If
            condition={
              permittedFieldsManagement(
                permittedFields?.noticeToPeriodDays?.roles
              ) || permittedFields?.noticeToPeriodDays
            }>
            <div className="flex w-full items-center space-x-2">
              <IconWrapper
                size={16}
                name="CalendarClock"
                className="hidden text-gray-500 desktop:block"
              />
              <TypographyText className="text-sm text-gray-600">
                {t(
                  'candidates:tabs:candidateOverview:profileInformation:noticeOfPeriods'
                )}
              </TypographyText>
              <If
                condition={
                  adminCanAction(currentRole?.code) &&
                  !permittedFields?.noticeToPeriodDays?.roles?.includes(
                    ROLE.Everyone
                  ) &&
                  !permittedFields?.noticeToPeriodDays?.client_user_visibility
                }>
                <Tooltip
                  classNameConfig={{ content: 'max-w-full' }}
                  content={t('candidates:tabs:candidateOverview:visibleTo', {
                    role: permittedFields?.noticeToPeriodDays?.roles?.join(', ')
                  })}>
                  <LockIcon />
                </Tooltip>
              </If>
            </div>

            <div className="w-full">
              <Controller
                control={control}
                name="noticeToPeriodDays"
                render={({ field: { onChange, value } }) => {
                  const formatNoticeOfPeriods = value
                    ? {
                        value: value,
                        supportingObj: {
                          name: value
                        }
                      }
                    : undefined
                  return (
                    <FormControlItem>
                      <DisableControlByPermission
                        applicantId={applicantId}
                        text={
                          <div className="flex items-center px-2 py-1.5 text-sm">
                            {value ? (
                              <div className="text-gray-900">{value}</div>
                            ) : (
                              <div className="text-gray-500">
                                {t(
                                  'candidates:tabs:candidateOverview:notAvailable'
                                )}
                              </div>
                            )}
                          </div>
                        }>
                        <InlineEditingNoActionsNativeSelect
                          creatable
                          onChange={(newValue) => {
                            onChange(newValue?.value || '')
                            return submitPartialField(
                              'noticeToPeriodDays',
                              trigger,
                              submit
                            )
                          }}
                          onCancelUpdate={(newValue) =>
                            onChange(newValue?.value)
                          }
                          options={ListSuggestNoticeOfPeriod.map((item) => ({
                            value: `${item.value} ${t('label:days')}`,
                            supportingObj: {
                              name: `${item.value} ${t('label:days')}`
                            }
                          }))}
                          placeholder={
                            t(
                              'candidates:tabs:candidateOverview:profileInformation:selectTimePeriod'
                            ) as string
                          }
                          size="sm"
                          classNameOverride={{
                            loadingMessage: `${t('label:loading')}`,
                            noOptionsMessage: `${t('label:noOptions')}`
                          }}
                          value={formatNoticeOfPeriods}>
                          <div className="flex items-center px-2 py-1.5 text-sm">
                            {value ? (
                              <div className="text-gray-900">{value}</div>
                            ) : (
                              <div className="text-gray-500">
                                {t(
                                  'candidates:tabs:candidateOverview:profileInformation:selectTimePeriod'
                                )}
                              </div>
                            )}
                          </div>
                        </InlineEditingNoActionsNativeSelect>
                      </DisableControlByPermission>
                    </FormControlItem>
                  )
                }}
              />
            </div>
          </If>
        </>
      ) : null}
      {!configHide.ownerId ? (
        <>
          <div className="flex items-center space-x-2">
            <IconWrapper
              size={16}
              name="UserCog"
              className="hidden text-gray-500 desktop:block"
            />
            <TypographyText className="text-sm text-gray-600">
              {t('candidates:tabs:candidateOverview:profileInformation:owner')}
            </TypographyText>
          </div>

          <div className="w-full">
            <Controller
              control={control}
              name="ownerId"
              render={({ field: { onChange, value } }) => {
                return (
                  <FormControlItem>
                    <DisableControlByPermission
                      applicantId={applicantId}
                      text={
                        <div className="flex items-center px-2 py-1.5 text-sm">
                          {value ? (
                            <>
                              <div className="mr-2">
                                <Avatar
                                  color={value?.supportingObj?.defaultColour}
                                  size="xs"
                                  src={value?.avatarVariants?.thumb?.url}
                                  alt={value?.supportingObj?.name}
                                />
                              </div>
                              <div className="text-gray-900">
                                {value?.supportingObj?.name}
                              </div>
                            </>
                          ) : (
                            <div className="text-gray-500">
                              {t(
                                'candidates:tabs:candidateOverview:notAvailable'
                              )}
                            </div>
                          )}
                        </div>
                      }>
                      <InlineEditingNoActionsAsyncSingleSearchWithSelect
                        onChange={(newValue) => {
                          onChange(newValue?.length && newValue[0])
                          return submitPartialField('ownerId', trigger, submit)
                        }}
                        onCancelUpdate={(newValue) => {
                          onChange(newValue?.length && newValue[0])
                        }}
                        promiseOptions={promiseMembersOwnerOptionsFetchAll}
                        placeholder={
                          t(
                            'candidates:tabs:candidateOverview:contactDetails:owner:search'
                          ) as string
                        }
                        size="sm"
                        configSelectOption={{
                          avatar: true,
                          supportingText: ['name', 'description']
                        }}
                        classNameOverride={{
                          loadingMessage: `${t('label:loading')}`,
                          noOptionsMessage: `${t('label:noOptions')}`
                        }}
                        value={value ? [value] : []}>
                        <div className="flex items-center px-2 py-1.5 text-sm">
                          {value ? (
                            <div className="flex items-center">
                              <div className="mr-2">
                                <Avatar
                                  color={value?.supportingObj?.defaultColour}
                                  size="xs"
                                  src={value?.avatarVariants?.thumb?.url}
                                  alt={value?.supportingObj?.name}
                                />
                              </div>
                              <TypographyText className="text-sm text-gray-900">
                                {value?.supportingObj?.name}
                              </TypographyText>
                            </div>
                          ) : (
                            <div className="text-gray-500">
                              {t(
                                'candidates:tabs:candidateOverview:contactDetails:owner:search'
                              )}
                            </div>
                          )}
                        </div>
                      </InlineEditingNoActionsAsyncSingleSearchWithSelect>
                    </DisableControlByPermission>
                  </FormControlItem>
                )
              }}
            />
          </div>
        </>
      ) : null}
      {!configHide.summary ? (
        <>
          <If
            condition={
              permittedFieldsManagement(permittedFields?.summary?.roles) ||
              permittedFields?.summary
            }>
            <div className="flex items-center space-x-2">
              <IconWrapper
                size={16}
                name="Book"
                className="hidden text-gray-500 desktop:block"
              />
              <TypographyText className="text-sm text-gray-600">
                {t('candidates:tabs:candidateOverview:summary:heading')}
              </TypographyText>
            </div>

            <div className="w-full">
              <DisableControlByPermission
                applicantId={applicantId}
                text={
                  <div className="flex items-center px-2 py-1.5 text-xl">
                    {permittedFields?.summary?.value ? (
                      <HTMLDisplay
                        content={permittedFields?.summary?.value}
                        className="max-w-full text-sm text-gray-900"
                      />
                    ) : (
                      <TypographyText className="text-sm text-gray-500">
                        {t('candidates:tabs:candidateOverview:notAvailable')}
                      </TypographyText>
                    )}
                  </div>
                }>
                <InlineEditingEditor
                  autoSave
                  localStorageId={`profile-${data?.id}-summary`}
                  className="min-w-full"
                  limit={100000}
                  showCount={false}
                  onChange={(newValue) => {
                    return onUpdateProfile({
                      summary: newValue as string,
                      paramType: 'summary'
                    }).then(() => true)
                  }}
                  placeholder={`${t(
                    'candidates:tabs:candidateOverview:summary:addSummary'
                  )}`}
                  size="sm"
                  value={permittedFields?.summary?.value}
                  tooltipActionCancel={{
                    title: `${t('button:cancel')}`
                  }}
                  tooltipActionSave={{
                    title: `${t('button:saveEnter')}`
                  }}>
                  <div className="flex items-center px-2 py-1.5 text-xl">
                    {permittedFields?.summary?.value ? (
                      <HTMLDisplay
                        content={permittedFields?.summary?.value}
                        className="max-w-full text-sm text-gray-900"
                      />
                    ) : (
                      <TypographyText className="text-sm text-gray-500">
                        {t(
                          'candidates:tabs:candidateOverview:summary:addSummary'
                        )}
                      </TypographyText>
                    )}
                  </div>
                </InlineEditingEditor>
              </DisableControlByPermission>
            </div>
          </If>
        </>
      ) : null}
      {!configHide.sourced ? (
        <>
          <div className="flex items-center space-x-2">
            <IconWrapper
              size={16}
              name="Search"
              className="hidden text-gray-500 desktop:block"
            />
            <TypographyText className="text-sm text-gray-600">
              {t(
                'candidates:tabs:candidateOverview:profileInformation:sourced'
              )}
            </TypographyText>
          </div>

          <div className="w-full">
            <Controller
              control={control}
              name="sourced"
              render={({ field: { onChange, value } }) => {
                const formatSourced = value
                  ? {
                      value: value || '',
                      supportingObj: {
                        name: data.sourcedDescription || ''
                      }
                    }
                  : undefined
                return (
                  <FormControlItem>
                    <DisableControlByPermission
                      applicantId={applicantId}
                      disabled={disableSourceReferral}
                      text={
                        <div className="flex items-center px-2 py-1.5 text-sm">
                          {value ? (
                            <div className="text-gray-900">
                              {
                                profileSourced.filter(
                                  (item: ISelectOption) => item.value === value
                                )[0]?.supportingObj?.name
                              }
                            </div>
                          ) : (
                            <div className="text-gray-500">
                              {t(
                                'candidates:tabs:candidateOverview:notAvailable'
                              )}
                            </div>
                          )}
                        </div>
                      }>
                      <InlineEditingNoActionsNativeSelect
                        onChange={(newValue) => {
                          onChange(newValue?.value || '')
                          return submitPartialField('sourced', trigger, submit)
                        }}
                        onCancelUpdate={(newValue) => onChange(newValue?.value)}
                        options={profileSourced}
                        isClearable={false}
                        placeholder={
                          t(
                            'candidates:tabs:candidateOverview:profileInformation:selectSource'
                          ) as string
                        }
                        size="sm"
                        classNameOverride={{
                          loadingMessage: `${t('label:loading')}`,
                          noOptionsMessage: `${t('label:noOptions')}`
                        }}
                        value={formatSourced}>
                        <div className="flex items-center px-2 py-1.5 text-sm">
                          {value ? (
                            <div className="text-gray-900">
                              {
                                profileSourced.filter(
                                  (item: ISelectOption) => item.value === value
                                )[0]?.supportingObj?.name
                              }
                            </div>
                          ) : (
                            <div className="text-gray-500">
                              {t(
                                'candidates:tabs:candidateOverview:profileInformation:selectSource'
                              )}
                            </div>
                          )}
                        </div>
                      </InlineEditingNoActionsNativeSelect>
                    </DisableControlByPermission>
                  </FormControlItem>
                )
              }}
            />
          </div>
        </>
      ) : null}
      {!configHide.sourcedName ? (
        <>
          <If condition={isShowSourceName}>
            <div className="flex items-center space-x-2">
              <IconWrapper
                size={16}
                name="UserPlus"
                className="hidden text-gray-500 desktop:block"
              />
              <TypographyText className="text-sm text-gray-600">
                {t(
                  'candidates:tabs:candidateOverview:profileInformation:sourcedName'
                )}
              </TypographyText>
            </div>

            <div className="w-full">
              <Controller
                control={control}
                name="sourcedName"
                render={({ field: { onChange, value } }) => {
                  const format = value
                    ? [
                        {
                          value: value,
                          supportingObj: {
                            name: data.sourcedNameDescription || ''
                          }
                        }
                      ]
                    : []
                  return (
                    <FormControlItem>
                      <DisableControlByPermission
                        applicantId={applicantId}
                        disabled={disableSourceReferral}
                        text={
                          <div className="flex items-center px-2 py-1.5 text-sm">
                            {value && data.sourcedNameDescription ? (
                              <div className="text-gray-900">
                                {data.sourcedNameDescription}
                              </div>
                            ) : (
                              <div className="text-gray-500">
                                {t(
                                  'candidates:tabs:candidateOverview:notAvailable'
                                )}
                              </div>
                            )}
                          </div>
                        }>
                        <InlineEditingNoActionsAsyncSingleSearchWithSelect
                          onChange={(newValue) => {
                            onChange(
                              newValue?.length > 0 ? newValue[0].value : ''
                            )
                            return submitPartialField(
                              'sourcedName',
                              trigger,
                              submit
                            )
                          }}
                          onCancelUpdate={(newValue) =>
                            onChange(
                              newValue?.length > 0 ? newValue[0].value : ''
                            )
                          }
                          promiseOptions={promiseProfileSourceListOptions}
                          placeholder={
                            t(
                              'candidates:tabs:candidateOverview:profileInformation:selectSource'
                            ) as string
                          }
                          size="sm"
                          classNameOverride={{
                            loadingMessage: `${t('label:loading')}`,
                            noOptionsMessage: `${t('label:noOptions')}`
                          }}
                          value={format}>
                          <div className="flex items-center px-2 py-1.5 text-sm">
                            {value && data.sourcedNameDescription ? (
                              <div className="text-gray-900">
                                {data.sourcedNameDescription}
                              </div>
                            ) : (
                              <div className="text-gray-500">
                                {t(
                                  'candidates:tabs:candidateOverview:profileInformation:selectSource'
                                )}
                              </div>
                            )}
                          </div>
                        </InlineEditingNoActionsAsyncSingleSearchWithSelect>
                      </DisableControlByPermission>
                    </FormControlItem>
                  )
                }}
              />
            </div>
          </If>
        </>
      ) : null}
      {!configHide.sourcedBy ? (
        <>
          {data?.user && (
            <>
              <div className="flex items-center space-x-2">
                <IconWrapper
                  size={16}
                  name="UserPlus"
                  className="hidden text-gray-500 desktop:block"
                />
                <TypographyText className="text-sm text-gray-600">
                  {t(
                    'candidates:tabs:candidateOverview:profileInformation:sourcedBy'
                  )}
                </TypographyText>
              </div>

              <div className="flex items-center px-2 py-1.5">
                <div className="mr-2">
                  <Avatar
                    color={data?.user?.defaultColour}
                    size="xs"
                    src={data?.user?.avatarVariants?.thumb?.url}
                    alt={data?.user?.fullName}
                  />
                </div>
                <TypographyText className="text-sm text-gray-900">
                  {data?.user?.fullName}
                </TypographyText>
              </div>
            </>
          )}
        </>
      ) : null}
      {!configHide.createdAt ? (
        <>
          {data?.createdAt && (
            <>
              <div className="flex items-center space-x-2">
                <IconWrapper
                  size={16}
                  name="Clock"
                  className="hidden text-gray-500 desktop:block"
                />
                <TypographyText className="text-sm text-gray-600">
                  {t('label:createdAt')}
                </TypographyText>
              </div>
              <div className="flex items-center px-2 py-1.5">
                <TypographyText className="text-sm text-gray-900">
                  {defaultFormatDate(new Date(data.createdAt))}
                </TypographyText>
              </div>
            </>
          )}
        </>
      ) : null}
      {!configHide.customFields && customFieldViewData.length > 0 && (
        <Controller
          control={control}
          name="customFields"
          defaultValue={{}}
          render={({ field: { onChange, value } }) => {
            const onChangeRoles = (roleIds: Array<number>) => {
              switch (roleIds.toString()) {
                // case EVERYONE_VALUE.toString():
                //   return `${t(
                //     'settings:custom_fields:permissions:everyoneName'
                //   )}`
                // case ADMIN_VALUE.toString():
                //   return `${t('settings:custom_fields:permissions:adminName')}`
                case ADMIN_RECRUITING_RECRUITER_VALUE.toString():
                  return `${t(
                    'settings:custom_fields:permissions:adminMemberName'
                  )}`
                default:
                  return ''
              }
            }

            return (
              <>
                {customFieldViewData.map((customField) => (
                  <>
                    <CustomField
                      lockContent={
                        customField.roleIds?.length &&
                        customField.roleIds.toString() !==
                          EVERYONE_VALUE.toString()
                          ? `${t(
                              'candidates:tabs:candidateOverview:visibleTo',
                              {
                                role: onChangeRoles(customField.roleIds)
                              }
                            )}`
                          : ''
                      }
                      type={
                        customField.type as CustomFieldComponentType['type']
                      }
                      display={
                        limitedMemberCanAction(currentRole?.code)
                          ? 'view_horizontal'
                          : 'inline_editing'
                      }
                      isLoadingSubmitButton={isLoadingUpdateProfile}
                      viewDefaultPlaceholder={
                        limitedMemberCanAction(currentRole?.code)
                          ? `${t(
                              'candidates:tabs:candidateOverview:notAvailable'
                            )}`
                          : '-'
                      }
                      name={customField.name}
                      label={customField.label}
                      error={formState.errors.customFields}
                      editingTriggerValidate={() => {
                        return submitPartialField(
                          'customFields',
                          trigger,
                          submit
                        )
                      }}
                      value={
                        Object.values(
                          (value || {}) as CustomFieldFormType
                        ).find(
                          (item) => String(item.id) === String(customField.id)
                        )?.value || ''
                      }
                      onChange={(fieldValue) => {
                        onChange({
                          ...value,
                          [customField.name]: {
                            ...customField,
                            value: fieldValue
                          }
                        })
                      }}
                      extraProps={{
                        options: customField.selectOptions
                      }}
                    />
                  </>
                ))}
              </>
            )
          }}
        />
      )}
    </div>
  )
}

export default ProfileInformationView
